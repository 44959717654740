.main-page-title {
    display: flex;
    justify-content: center;
}

.main-page-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    height: 700px;
}

/* .other-recipes-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
} */

/* .other-recipes-container button {
    width: 80px;
    height: auto;
} */

.refresh-button {
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    border-radius: 5px;
    width: 80px;
    height: 25px;
}

.refresh-button:hover {
    background-color: #e5e5e5;
}

.your-recipes-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 0;
}

.your-recipes-container button {
    background: linear-gradient(90deg, #e67e22, #d35400);
    color: #ffffff;
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: transform 0.1s, box-shadow 0.1s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    margin-right: 10px;
    opacity: 0.9;
}

.your-recipes-container button:hover {
    transform: translateY(-1px);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.07);
}

.your-recipes-container button:active {
    transform: translateY(0);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}

.your-recipes-container button:focus {
    outline: none;
}

.your-recipes-container button:last-child {
    margin-right: 0;
}

h2 a {
    display: inline-block;
    background: linear-gradient(90deg, #d35400, #c0392b);
    color: #ffffff;
    border-radius: 3px;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 24px;
    transition: background 0.3s, transform 0.2s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 0.9;
}

h2 a:hover {
    animation: wobble 0.8s ease-in-out;
}

h2 a:active {
    transform: translateY(0);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

h2 a:focus {
    outline: none;
}

.category-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.category-link {
    text-decoration: none;
    color: inherit;
    padding: 10px 25px;
    border: 2px solid currentColor;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.category-link:hover {
    background-color: rgba(255, 165, 0, 0.1);
}

/* Wobble animation */
@keyframes wobble {
  15% {
    transform: translateX(5px);
  }
  30% {
    transform: translateX(-5px);
  }
  45% {
    transform: translateX(3px);
  }
  60% {
    transform: translateX(-3px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}
